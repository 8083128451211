












	import { Component, Vue } from 'vue-property-decorator';
	import AppContainerBase from '@/components/util/AppContainerBase.vue';
	import AppFormBase from '@/components/util/AppBaseForm.vue';
	import { Form } from '@/model/util/Form';
	import { EValidate } from '@/model/util/ValidationType';
	import { IItemSelect } from '@/model/util/IItemSelect';
	import Util from '@/utils/Util';
	import { internet } from '@/utils/Internet';
	import { IFilterData } from '@/model/main/IFilterData';
	import { Icon } from '@/model/util/Icon';
	
	@Component({
		name: 'MainUser',
		components: {
			AppContainerBase,
			AppFormBase,
		},
	})
	export default class MainUser extends Vue {
		public isLoading = false;
		public reset = false;
		public roles: Array<any> = []
		form: Array<Form> = [
			{
				type: 'input',
				label: 'Nombre',
				rules: [EValidate.REQUIRED],
				model: {
					name: 'name',
					extra: '',
					value: '',
				},
				required: true,
				field: 12,
			},
			{
				type: 'input',
				label: 'Apellido',
				rules: [EValidate.REQUIRED],
				model: {
					name: 'surname',
					extra: '',
					value: '',
				},
				required: true,
				field: 12,
			},
			{
				type: 'input',
				label: 'Username único',
				rules: [EValidate.REQUIRED],
				model: {
					name: 'user',
					extra: '',
					value: '',
				},
				required: true,
				field: 12,
			},
			{
				type: 'input',
				label: 'Dirección',
				rules: [EValidate.REQUIRED],
				model: {
					name: 'address',
					extra: '',
					value: '',
				},
				required: true,
				field: 12,
			},
			{
				type: 'email',
				label: 'Email usuario',
				rules: [EValidate.REQUIRED],
				model: {
					name: 'email',
					extra: '',
					value: '',
				},
				required: true,
				field: 12,
			},
			{
				type: 'password',
				label: 'Contraseña',
				rules: [EValidate.REQUIRED],
				model: {
					name: 'password',
					extra: '',
					value: '',
				},
				required: true,
				field: 12,
			},
			{
				type: 'select',
				label: 'Roles',
				rules: [EValidate.REQUIRED],
				model: {
					name: 'role',
					extra: [],
					value: '',
				},
				required: true,
				field: 12,
			},
			{
				type: 'select',
				label: 'Tipo de documento',
				rules: [EValidate.REQUIRED],
				model: {
					name: 'documentType',
					extra: [],
					value: '',
				},
				required: true,
				field: 12,
			},
			{
				type: 'input',
				label: 'Numero de documento',
				rules: [EValidate.REQUIRED],
				model: {
					name: 'documentNumber',
					extra: '',
					value: '',
				},
				required: true,
				field: 12,
			},
			{
				type: 'input',
				label: 'Teléfono',
				rules: [EValidate.REQUIRED],
				model: {
					name: 'phone',
					extra: '',
					value: '',
				},
				required: true,
				field: 12,
			},						
		];

		created(): void{

			this.isLoading = true;

			const request_1 = internet
				.newRequest()
				.get(process.env.VUE_APP_API_USERS+'/role'); //cambiar por endpoint traer roles desde api users

			Util.waitForPromises([request_1])
				.then((result) => {
					this.roles = result[0].data
	
					this.form[6].model.extra = this.getItemRoles;
					this.form[7].model.extra = this.getItemTypeDocument;
					this.form = JSON.parse(
						JSON.stringify(this.form)
					);
					this.isLoading = false;
					console.log("Roles:", result);
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => {
					this.isLoading = false;
				});
		}
		get getItemTypeDocument(): Array<IItemSelect> {
			return [
				{
					text: 'Rut',
					value: 'Rut',
				},
				{
					text: 'Pasaporte',
					value: 'Pasaporte',
				},
			];
		}

		get getItemRoles(): Array<IItemSelect> {
			const finded: Array<IItemSelect> = [];
			for (let item of this.roles) {
				finded.push({
					text: item.visualName,
					value: item.name,
				});
			}

			return finded;
		}
		public onGetData(payload: any): void {
			console.log("payload:", payload);

			this.isLoading = true;
			internet
			.newRequest()
			.post(process.env.VUE_APP_API_USERS+'/user',

					{
                        "firstName":payload.name,
                        "lastName":payload.surname,
                        "email":payload.email,
                        "username":payload.user,
                        "role":payload.role,
                        "documentType":payload.documentType,
                        "documentNumber":payload.documentNumber,
                        "address":payload.address,
                        "phone":payload.phone,
						"password":payload.password
					}
				)
			.then((response) => {
				console.log("response_usuario", response)
				
				Util.showMessage(
					'Usuario creado con éxito.',
					Icon.SUCCESS
				);
			})
			.catch(() => {
				Util.showMessage(
					'Usuario no pudo ser creado.',
					Icon.WARNING
				);
			})
			.finally(() => {
				this.isLoading = false;
			});

		}
	}
